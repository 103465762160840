<template>
    <v-dialog
        v-model="dialog"
        width="800"
        persistent
    >
        <v-card>
            <v-card-title class="text-h5 primary white--text">
                Set Term Dates
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    color="primary lighten-2"
                    small
                    fab
                    style="position:absolute; top:10px; right:10px; z-index:100"
                    @click="close"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="terms"
                    :loading="loading" 
                    height="30vh"  
                    hide-default-footer             
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.year }}</td>

                            <td>{{ item.term }}</td>

                            <td>
                                <DatePicker
                                    :initial-date="item.term_start"
                                    :disabled="item.disabled"
                                    field="term_start"
                                    :term="item.term"
                                    v-on:set-date="setDate"
                                />
                            </td>

                            <td>
                               <DatePicker
                                    :initial-date="item.term_end"
                                    :disabled="item.disabled"
                                    :term="item.term"
                                    field="term_end"
                                    v-on:set-date="setDate"
                               />
                            </td>                                                       
                            
                            <td>
                                <v-btn
                                    outlined
                                    color="primary"
                                    @click="editTerm(item)"
                                    small
                                >
                                    <v-icon
                                        medium
                                        left
                                        small
                                    >
                                        {{ item.btnIcon}}
                                    </v-icon>
                                    {{ item.btnText }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-overlay
                absolute
                :value="overlay"                    
                class="pa-5"
            > 
                <v-progress-circular
                    indeterminate 
                    size="64"                            
                    color="primary"                                
                ></v-progress-circular>                   
            </v-overlay>

            <v-snackbar
                v-model="snackbar.show"
                :color="snackbar.color"
                timeout="2000"
            >
                {{ snackbar.text}}
                <template
                    v-slot:action="{ attrs }"
                >
                    <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DatePicker from './AttendanceDatePicker.vue';
export default {
    props: {
        initialDialog: Boolean,
    },

    components: {
        DatePicker
    },

    watch: {
        initialDialog (val) {
            this.dialog = val;
            this.overlay = true;
            if(val) {
                this.initialize();
                return;
            }
        }
    },

    data () {
        return {
            dialog: false,
            headers:[
                { text: 'Academic Year', value: 'year', width: '150' },
                { text: 'Term', value: 'term', width: '100' },
                { text: 'Start Date', value: 'term_start' },
                { text: 'End Date', value: 'term_end', sortable: false },
                { text: 'Actions', value: 'actions'}
            ],
            terms: [],
            loading: false,
            menuTermStart: false,
            overlay: false,
            snackbar: {
                text: null,
                color: null,
                show: false,
            }
        }
    },
    
    methods: {
        ...mapActions({
            getTermDetails: 'admin/getTermDetails',
            postTermDetails: 'admin/postTermDetails',
        }),

        ...mapMutations({
            setSelectedTerm: 'admin/setSelectedTerm',            
        }),

        async initialize () {
            this.terms = [];
            try {
                const { data } = await this.getTermDetails();
                this.mapTermDetails(data)
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }
            this.dialog = true; 
            this.overlay = false;
        },

        mapTermDetails (data) {
            data.forEach(term => {
                let { academic_year_id } = term;
                term.year = academic_year_id.toString().substring(0,4) + '-' + academic_year_id.toString().substring(4)
                term.disabled = true;
                term.btnText = 'Edit'
                term.btnIcon = 'mdi-pencil'
            });
            this.terms = data;
        },

        editTerm (term) {
            let index = this.terms.indexOf(term);
            if( this.terms[index].btnText == 'Edit'){
                this.terms[index].disabled = false;
                this.terms[index].btnIcon = 'mdi-content-save';
                this.terms[index].btnText = 'Save'
                return;
            }

            this.terms[index].disabled = true;
            this.terms[index].btnIcon = 'mdi-pencil';
            this.terms[index].btnText = 'Edit'
            this.save(this.terms[index]);
        },

        setDate (val) {
            this.terms.forEach(record => {
                if(record.term == val.term){
                    record[val.field] = val.date
                }
            })
        },

        async save(term){
            this.overlay = true;
            this.setSelectedTerm(term);
            try {
                await this.postTermDetails();
                this.snackbar.text = `Term ${term.term} term dates saved successfully.`;
                this.snackbar.color = 'primary';
                this.snackbar.show = true;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackbar.text = `Error occured, ${term.term} term dates not saved.`;
                this.snackbar.color = 'red';
                this.snackbar.show = true;
            }
            this.overlay = false;
        },


        close () {
            this.dialog = false;
            this.$emit('set-dialog', false)
        }


    }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import admin from './admin'
import termReports from './termReports'
import markBook from './markBook'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URI

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth, 
        termReports,
        admin,
        markBook,
    }
})
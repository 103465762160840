<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"
            light                        
        >    
            
            <v-toolbar
                color="primary white--text"
                light
            >
               <v-toolbar-title>
                    <v-btn
                        v-if="admin || spreadsheetAccess"
                        text
                        dark
                        outlined
                        small
                        class="mr-4"
                        @click="backToTeachers"
                    >
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        <div>Back</div>
                    </v-btn>
                    <span>Select Subject Class</span>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text
                style="height: 60vh; overflow-y: auto"
            >
                <v-list 
                    light                
                >
                    <v-list-item-group v-model="lessonSelected">
                        <v-list-item
                            v-for="(item, i) in lessons"
                            :key="i"
                            @click="setLesson(item)"
                        >
                            <v-list-item-avatar>
                                <v-icon class="primary white--text">
                                    mdi-account-group
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.lesson"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                 <div
                    v-if="lessons.length == 0"
                    class="d-flex justify-center text-h6 primary--text font-weight-light"
                >
                    <v-alert
                        text
                        type="warning"
                        prominent
                       
                        colored-border
                        color="primary"
                    >
                        No classes assigned
                    </v-alert>
                </div>
            </v-card-text>
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                color="grey lighten-5"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>                   
            </v-overlay>
        </v-card>
    </v-expand-transition>         
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
       props: {
        parentComponent: {
            type: String,
            default: null,
        },

        employeeId: {
            type: Number,
            default: null,
        },

        spreadsheetAccess: {
            type: Boolean,
            default: false,
        }
    },    
    mounted: function  (){ 
        if(!this.admin || (this.admin && this.employeeId)){
            this.getLessons();
        }
    },

    data: () => ({
        lessons: [],
        lessonSelected: {lesson: '', className: '', subjectCode: '', subjectTitle: ''},
        open: true,
        clear: true,       
        focus: false,
        records: [],        
    }),
    
    computed: {
        ...mapGetters({            
            id: 'auth/getEmployeeId',
            lessonRecords: 'termReports/getLessons',
            expand: 'termReports/getExpandLessonOptions',
            studentsTotal: 'termReports/getSubjectStudentsTotal',
            studentsRegistered: 'termReports/getSubjectStudentsRegistered',
            studentsMarksEntered: 'termReports/getSubjectStudentsMarksEntered',
            overlay: 'termReports/getOverlayLessons',
            admin: 'auth/getAdmin',                            
        }),
    },

    methods: {
        ...mapActions({
           getLessonRecords: 'termReports/getLessons',
           getStudents: 'termReports/getStudents',           
        }),

        ...mapMutations({
            setLessonSelected: 'termReports/setLessonSelected',
            setExpand: 'termReports/setExpandLessonOptions',
            setMarkSheetLoading: 'termReports/setMarkSheetLoading',
            markSheetOverlay: 'termReports/setMarkSheetOverlay',
            table2Records: 'termReports/setTable2Records',
            setOverlay: 'termReports/setOverlayLessons',
        }),

        async setLesson(lesson){
            // console.log(lesson);
            this.setLessonSelected(lesson);
            this.$emit('lesson-selected', lesson);
            this.setExpand(false);
            console.log(this.parentComponent);
            
            if(this.parentComponent === 'enter-marks'){
                // console.log('enter marks');
                setTimeout(() => {
                    this.setMarkSheetLoading(true);
                }, 700)
                // console.log(this.id);
                await this.getStudents(this.id);
            }                     
        },

        async getLessons(){
            //console.log(this.records);
            let employeeId = this.employeeId ? this.employeeId : this.id;
            await this.getLessonRecords(employeeId);            
            this.setMarkSheetLoading(false);
            this.setExpand(true);
            //console.log(this.lessonRecords);            
            this.lessonRecords.forEach(lessonRecord =>{
                let subject = lessonRecord.subject.title;
                this.lessons.push({
                    lesson: lessonRecord.form_class_id + ' ' + subject,
                    className: lessonRecord.form_class_id,                    
                    subjectCode: lessonRecord.subject.id,
                    subjectTitle: subject,
                    formLevel: lessonRecord.form_class.form_level,
                })
                this.focus = true;
            });
            this.setOverlay(false);             
        },

        backToTeachers () {
            this.setExpand(false);
            console.log('back to teachers');
            setTimeout(() => {
                this.$emit('display-teachers')
            }, 800);
        }

    }
}
</script>
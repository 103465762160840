<template>
    <v-card
        width="900"
        
        class="mx-auto pa-4 mt-4"        
        elevation="2"
        outlined
    >
        <v-tabs
            v-model="tab"
            grow
        >
            <v-tab
                v-for="item in tabOptions"
                :key="item"
            >
                {{ item }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab"> 
            <v-tab-item v-if="editSubjects">
                <edit-view-subjects 
                    v-bind:subjects="subjects"
                    v-on:update-subjects="initialize"
                ></edit-view-subjects>                  
            </v-tab-item>
            
            <v-tab-item>
                <subjects-student-assignment
                    v-bind:subjects="subjects"
                    v-bind:teachers="teachers"
                ></subjects-student-assignment>                         
            </v-tab-item>

            <v-tab-item>
                <batch-student-subject-assignment
                    v-bind:subjects="subjects"
                    v-bind:teachers="teachers"
                    v-bind:formLevels="formLevels"
                    v-bind:formClasses="formClasses"
                    v-bind:students="students"
                    v-bind:teacherFormClassLevel="teacherFormClassLevel"
                    v-bind:tab="tab"
                    v-on:show-overlay="showOverlay"
                ></batch-student-subject-assignment>
            </v-tab-item>
               
        </v-tabs-items>
         
       <v-overlay
            absolute
            :value="overlay"
            opacity="0.7"
        >
            <v-progress-circular 
                indeterminate 
                size="64"                    
                color="white"
            ></v-progress-circular>   
        </v-overlay>

         
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditViewSubjects from './AdminSubjectsEditView';
import SubjectsStudentAssignment from './AdminSubjectsStudentAssignment';
import BatchStudentSubjectAssignment from './AdminSubjectsStudentBatch';
export default {
    created: function () {
        this.initialize();
    },

    components: {
        EditViewSubjects,
        SubjectsStudentAssignment,
        BatchStudentSubjectAssignment,
    },
    
    data: () => ({
        
        subjects: [],
        teachers: [],
        formLevels: [],
        formClasses: [],
        students: [],
        tabOptions: [],
        tabOptionsAll: [
            'Edit/View Subjects', 'Subject Student Assignment', 'Batch Assignment'
        ],
        tab: null,
        overlay: false,
        editSubjects: false,
        teacherFormClassLevel: null, 
    }),

    watch: {
        // tab: {
        //     handler (val) {
        //         console.log(val)
        //     }
        // }
    },

    computed: {
        ...mapGetters({
            adminPermissions: 'auth/getAdminPermissions',
            classId: 'auth/getClassId',
        })
    },

    methods: {
        ...mapActions({            
            getSubjects: 'admin/getSubjects',            
            getEmployees: 'admin/getEmployees',
            getFormLevels: 'admin/getFormLevels',
            getFormClasses: 'admin/getFormClasses',
            getStudents: 'admin/getStudents',           
        }),

        async initialize () {
            this.overlay = true;

            this.mapTabs();

            const promiseSubjects = this.getSubjects();
            const promiseEmployees = this.getEmployees();
            const promiseFormLevels = this.getFormLevels();
            const promiseFormClasses = this.getFormClasses();
            const promiseStudents = this.getStudents();

            try {
                const [
                    { data: dataSubjects },
                    { data: dataEmployees },
                    { data: dataFormLevels },
                    { data: dataFormClasses },
                    { data: dataStudents }
                ] = await Promise.all([
                    promiseSubjects, 
                    promiseEmployees, 
                    promiseFormLevels, 
                    promiseFormClasses,
                    promiseStudents,
                ])

                dataFormLevels.forEach(value => {
                    this.formLevels.push(value.form_level)
                })
                
                this.formClasses = dataFormClasses.filter(value => {
                    if(value.id == this.classId){
                        this.teacherFormClassLevel = value.form_level
                    }
                    if(value.form_level){
                        return value;
                    } 
                });

                // console.log(this.formClasses)

                this.subjects = [...dataSubjects];

                this.teachers = dataEmployees.filter(value => {
                    // console.log(value);
                    if(!value.deleted_at){
                        value.name = value.first_name + ' ' + value.last_name;
                        value.display_name = value.first_name[0] + '. ' + value.last_name;
                        return value;
                    }               
                });
    
                this.students = dataStudents.map(value => {
                    return {
                        id: value.id,
                        name: value.last_name + ' ' + value.first_name,
                        form_level: value.form_level,
                        class_id: value.class_id
                    }
                });

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }

            this.overlay = false;
        },

        mapTabs () {

            let permissions = JSON.parse(this.adminPermissions);
            // console.log(permissions);

            permissions.forEach(permission => {
                this.tabOptions = this.tabOptionsAll.filter(value => {
                    return value != 'Edit/View Subjects'
                })

                if(permission.permission_id ==  11){
                    this.tabOptions = [...this.tabOptionsAll]
                    this.editSubjects = true
                }
            })
        },

        showOverlay (display = false) {
            this.overlay = display;            
        },

    }
}
</script>
<template>
     <v-row>
        <v-col class="pb-0" cols="8">
            <v-card width="700" flat>
                <v-card-subtitle class="font-weight-black primary--text">{{ selectedDate }}</v-card-subtitle>
                
                <v-card-text>                    
                    <ChartBar
                        v-if="dataLoaded"
                        :chart-data="chartData"
                    ></ChartBar>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="primary">Total Attendance: {{ totalAttendance }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary">Late Attendance: {{ lateAttendance }}</v-btn>
                </v-card-actions>

                <v-overlay
                    absolute
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9" 
                >
                    <v-progress-circular
                        indeterminate 
                        size="64"                        
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-col>
        <v-col
            cols="4"
        >
            <v-date-picker
                label="Select Date"
                v-model="dateSelected"
                elevation="6"
                @change="setdateSelected"
                full-width
            ></v-date-picker>

            <!-- <v-btn
                outlined
                block
                class="mt-3"
                color="primary"
                @click="clearDates"
            >
                Clear Dates
            </v-btn> -->

             <v-btn
                color="primary"
                dark
                @click="displayReport"
                block
                class="mt-6"
            >
                Display Report
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
// import DatePickerRange from './DatePickerRange'
import ChartBar from './ChartBar.vue'
// import ChartDoughnut from './ChartDoughnut.vue';
import { format, parseISO } from 'date-fns';
export default {
    props: {
        formClasses: {
            type: Array,
            default: () => []
        }
    },


    created () {
        this.initialize();
        
    },

    components: {
        // DatePickerRange,
        ChartBar,
        // ChartDoughnut, 
    },

    watch: {
        // dateSelected (val) {
        //     if(val.length == 1){
        //         this.setAttendanceSummaryDates(this.dateSelected);
        //         this.selectedDate = format(parseISO(this.dateSelected), 'EEEE, d LLLL yyyy')
        //         this.$emit('set-date-range', this.dateSelected);
        //         this.getChartData();   
        //     } 
        // }
    },

    data: () => ({
        dateSelected: null,
        selectedClassId: null,
        attendanceData: {
            datasets: [{
                label: 'Attendance',
                backgroundColor: '#f87979',
                data: [40, 20, 12]
            }]
        },
        chartData: {
            labels: ['Form 1', 'Form 2', 'Form 3', 'Form 4', 'Form 5', 'Form 6'],
            datasets: []
        },
        chartOptions: {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                
            },
            animation: {
                animateScale: true
            },  
            cutout: '80%',
            text: '39%',
        },
        dataLoaded: false,
        overlay: false,
        selectedDate: null,
        totalAttendance: '--',
        lateAttendance: '--'
    }),

    methods: {
        ...mapMutations({
            setAttendanceSummaryDate: 'admin/setAttendanceSummaryDate',
            
        }),

        ...mapActions({
            getAttendanceSummaryData: 'admin/getAttendanceSummaryData',
        }),

        initialize () {
            this.dateSelected = null;
            const todayFormatted = format(new Date(), 'yyyy-MM-dd');
            this.selectedDate = format(new Date(), 'EEEE, d LLLL yyyy');
            this.dateSelected = todayFormatted;
            this.setdateSelected();
            this.dateSelected = null;
        },

        setdateSelected () {
            this.setAttendanceSummaryDate(this.dateSelected);
            this.$emit('set-date-range', this.dateSelected);
           
            this.selectedDate = this.dateSelected ?  format(parseISO(this.dateSelected), 'EEEE, d LLLL yyyy') : format(new Date(), 'EEEE, d LLLL yyyy');
            this.getChartData();            
        },

        compareDates(date1String, date2String){
            const date1 = new Date(date1String);
            const date2 = new Date(date2String);
            if(date1 < date2 ) return -1;
            if(date1 > date2 ) return 1;
            return 0;
        },

        async getChartData () {
            this.overlay = true;
            try {
                const { data } = await this.getAttendanceSummaryData();
                if(!Array.isArray(data)) 
                this.mapChartData(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },

        mapChartData (data) {
            let dataSets = [];
            let dataPresent = [];
            let dataAbsent = [];
            this.chartData.datasets = [];
            const [startDate] = Object.values(data);
            const { attendance_records } = startDate;
            const { attendance_school: { attendance: attendanceSchool } } = startDate;
            const { attendance_late: { attendance: attendanceLate }} = startDate;

            this.totalAttendance = attendanceSchool;
            this.lateAttendance = attendanceLate;
            Object.keys(attendance_records).forEach(formLevel => {
                dataPresent.push(attendance_records[formLevel].attendance);
                dataAbsent.push(attendance_records[formLevel].total - attendance_records[formLevel].attendance)
            })

            // Object.keys(data).forEach( date => {
            //     console.log(data);
            //     const { attendance_records } = data[date];
            //     const { attendance_school : { attendance } } = data[date];
            //     const { attendance_late: { attendance: attendanceLate } } = data[date];
            //     return;
            // })

            dataSets.push(
                {
                    label: 'Present',
                    data: dataPresent,
                    // backgroundColor:'rgba(54, 162, 235, 0.2)',
                    backgroundColor:'rgba(48, 88, 172, 0.4)',
                }
            )
            dataSets.push(
                {
                    label: 'Absent',
                    data: dataAbsent,
                    backgroundColor: 'rgba(190, 190, 190, 0.2)',                           
                }
            )
            this.chartData.datasets = dataSets;
            this.dataLoaded = true;
        },

        clearDates ()
        {
            this.dateSelected = [];
        },

        displayReport () {
            this.$emit('display-report')
        },

    }
}
</script>
<template>
    <v-card
        class="mt-4"
        flat
    >
        <v-row align="center" justify="center">
            <v-col cols="5">
                <v-autocomplete
                    v-model="selectedSubject"
                    label="Subject"
                    hide-details
                    outlined
                    dense
                    :items="subjects"
                    item-text="title"
                    item-value="id"
                    chips
                    small-chips
                    deletable-chips
                    @change="getStudentsAssigned"
                    height="20"
                ></v-autocomplete>
            </v-col>

            <v-col cols="2">
                <v-select
                    v-model="selectedFormLevel"
                    label="Form"
                    hide-details
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    :items="formLevels"
                    @change="setStudentsList"
                    :disabled="disabledFormLevel"
                ></v-select>
            </v-col>

            <v-col cols="3">
                <v-select
                    v-model="selectedFormClass"
                    label="Class"
                    hide-details
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    :items="formClassOptions"
                    @change="setStudentsList"
                    :disabled="disabledFormClass"
                ></v-select>
            </v-col>

            <v-col cols="2">
                <v-select
                    v-model="displayStudents"
                    outlined
                    label="Display"
                    :items="displayOptions"
                    hide-details
                    dense
                    @change="filterDisplay"
                ></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" class="pt-0">
                <v-autocomplete
                    v-model="selectedTeacher"
                    label="Teacher"
                    hide-details
                    outlined
                    dense
                    :items="teachers"
                    item-text="display_name"
                    item-value="id"
                    chips
                    small-chips
                    deletable-chips
                ></v-autocomplete>
            </v-col>

            <v-col cols="2" class="pt-0">
                <v-text-field
                    outlined
                    disabled
                    label="Assigned"
                    v-model="studentsAssignedCount"
                    hide-details
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="4" class="pt-0">
                <v-text-field
                    v-model="search"
                    label="Search Student"
                    append-icon="mdi-magnify"
                    outlined
                    clearable
                    hide-details
                    dense
                ></v-text-field>
            </v-col>


        </v-row>

        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="studentsList"
            fixed-header
            height="48vh"
            :search="search"
            :loading="loading"
            show-select
            dense
        >
            <template v-slot:[`item.assigned`]="{ item }">
                <v-icon
                    v-if="item.assigned"
                    color="primary"
                >
                    mdi-checkbox-marked-outline
                </v-icon>
            </template>
        </v-data-table>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="assignStudents"
            >
                Assign Selected Students
            </v-btn>
        </v-card-actions>

        <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :centered="snackCentered"
        >
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        subjects: {
            type: Array,
            default: function () {
                return [];
            }
        },

        teachers: {
            type: Array,
            default: function () {
                return [];
            }
        },

        formLevels: {
            type: Array,
            default: function () {
                return [];
            }
        },

        formClasses: {
            type: Array,
            default: function () {
                return [];
            }
        },

        students: {
            type: Array,
            default: function () {
                return [];
            }
        },

        teacherFormClassLevel: {
            type: Number
        },

        tab: {
            type: Number
        }
    },

    mounted: function () {
        this.initialize();
    },

    computed: {
        formClassOptions () {
            let arr = [];
            // if(this.selectedFormLevel && this.classId){

            //     this.formClasses.forEach(value => {
            //         if(value.id == this.classId) arr.push(value.id)
            //     })
            //     // return this.formClasses.filter(value => {
            //     //     if(value.id == this.classId )
            //     //     return value;
            //     // })
            // }
            if(this.selectedFormLevel){
                this.formClasses.forEach(value => {
                    if(value.form_level == this.selectedFormLevel) arr.push(value.id)
                })
                // return this.formClasses.filter(value => {
                //     if(value.form_level == this.selectedFormLevel )
                //     return value;
                // })
            }
            // return this.formClasses;
            
            return arr;
        },

        studentsAssignedCount () {
            if(this.subjectStudentsAssigned.length == 0) return '-';
            return this.subjectStudentsAssigned.length;
        },

        ...mapGetters({
            classId: 'auth/getClassId',
        })


    },

    data: () => ({
        selectedSubject: null,
        selectedTeacher: null,
        selectedFormLevel: null,
        selectedFormClass: null,
        headers: [
            {text: 'ID', align: 'start', value: 'id', width: '100'},
            {text: 'Name',  value: 'name'},
            {text: 'Class', value: 'class_id', width: '100'},
            {text: 'Teacher',  value: 'teacher' },
            {text: 'Assigned',  value: 'assigned', align: 'center' },
        ],
        search: '',
        loading: false,
        selected: [],
        studentsList: [],
        studentsAssigned: [],
        subjectStudentsAssigned: [],
        displayOptions: [ "All", "Assigned", "Unassigned"],
        displayStudents: "",
        overlay: false,
        overlayText: '',
        snackbar: false,
        snackColor: '',
        snackText: '',
        snackCentered: false,
        disabledFormLevel: false,
        disabledFormClass: false,
    }),

    watch: {
        selectedFormLevel: {
            handler () {
                // console.log("form level changed");
                this.selectedFormClass = null;
                this.displayStudents = "All";
                this.setStudentsList();
            }
        },


        selectedFormClass: {
            handler () {
                // console.log(`Form Class: ${value}`);
                this.displayStudents = "All";
            }
        },

        formClassOptions: {
            handler () {
                this.selectedFormClass = this.classId
                if(this.classId) this.disabledFormClass = true;
            }
        },

        tab: {
            handler (val) {
                if(val == 1) this.initialize();
            }
        }
    },

    methods: {
        ...mapActions({
            getSubjectStudents: 'admin/getSubjectStudents',
            postSubjectStudentsAssignment: 'admin/postSubjectStudentsAssignmentBatch',
        }),

        ...mapMutations({
            setSubject: 'admin/setSubjectSelected',
            setSubjectStudentsAssignment: 'admin/setSubjectStudentsAssignment',
        }),

        initialize () {
            console.log("Initializing...");
            // console.log(this.teacherFormClassLevel)
            // console.log(this.formClassOptions)
            this.displayStudents = "All";
            this.selected = [];
            this.studentsList = [];
            this.studentsAssigned = [];
            this.subjectStudentsAssigned = [];

            if(this.teacherFormClassLevel){
                this.disabledFormLevel = true
                this.selectedFormLevel = this.teacherFormClassLevel
            }
            // if(this.classId){
            //     // this.disabledFormClass = true
            //     // console.log(this.classId)
            //     this.selectedFormClass = this.classId;
            //     console.log(this.selectedFormClass);
            // }
            this.$emit('show-overlay', false)
        },

        async getStudentsAssigned () {
            if(this.selectedSubject){
                this.setSubject(this.selectedSubject);
                try {
                    let response = await this.getSubjectStudents();
                    // console.log(response);
                    this.studentsAssigned = response.data;
                    // console.log(this.studentsAssigned);
                    // console.log(`Get Students Assigned`);
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                }
            }
            this.setStudentsList();
        },

        setStudentsList () {
            if(this.selectedSubject && this.selectedFormLevel && !this.selectedFormClass)
            {
                this.studentsList = this.students.filter(value => {
                    if(value.form_level == this.selectedFormLevel)
                    return value;
                })
                this.setStudentsAssigned();
            }
            else if(this.selectedSubject && this.selectedFormClass)
            {
                this.studentsList = this.students.filter(value => {
                    if(value.class_id == this.selectedFormClass)
                    return value;
                })
                this.setStudentsAssigned();
            }
            else if(this.selectedSubject && !this.selectedFormLevel && !this.selectedFormClass)
            {
                this.studentsList = [];
            }
        },

        setStudentsAssigned () {
            let student = null, teacher = null;
            this.selected = [];
            this.subjectStudentsAssigned = [];
            // console.log(this.studentsAssigned);
            this.studentsList = this.studentsList.map(value => {
                student = this.studentsAssigned.filter(item => {
                    if(item.id == value.id) return item;
                })
                value.assigned = false;
                if(student.length > 0){
                    teacher = null;
                    if(student[0].employee_id){
                        teacher = this.teachers.filter(teacher => {
                            if(teacher.id == student[0].employee_id)
                            return teacher;
                        })[0].display_name;

                    }
                    value.teacher = teacher;
                    value.assigned = true;
                    this.subjectStudentsAssigned.push(value);
                    // this.selected.push(value);
                    // console.log(student);
                }
                return value;
            })
        },

        assignStudents () {
            this.setSubjectStudentsAssignment({
                form_level: this.selectedFormLevel,
                class_id: this.selectedFormClass,
                students: this.selected,
                employee_id: this.selectedTeacher,
                subject_id: this.selectedSubject
            })

            if(this.selected.length > 0) this.postAssignment();
            else{
                this.snackColor = "red";
                this.snackText = "No students selected for assignment";
                this.snackbar = true;
                this.snackCentered = true;
            }
        },

        async postAssignment () {
            this.$emit('show-overlay', true)
            try {
                let response = await this.postSubjectStudentsAssignment();
                console.log(response);
                this.displayStudents = "All";
                this.getStudentsAssigned();
                // this.setStudentsList();
                this.$emit('show-overlay', false);
                this.$nextTick(() => {
                    let msg = `${ this.selected.length} Student Assigned`;
                    if(this.selected.length > 1)
                    msg = `${ this.selected.length} Students Assigned`;
                    this.snackText = msg;
                    this.snackColor = "primary";
                    this.snackCentered = false;
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackText = "An error has occured.";
                this.snackColor = "red";
                this.snackCentered = false;
            }
            this.snackbar = true;
        },

        filterDisplay () {
            let studentAssigned = null;
            switch (this.displayStudents){
                case "All":
                    this.setStudentsList();
                    break;
                case "Assigned":
                    this.setStudentsList();
                    this.studentsList = this.studentsList.filter(value => {
                        studentAssigned = this.studentsAssigned.filter(item => {
                            if(item.id == value.id) return item;
                        })
                        if(studentAssigned.length > 0){
                            return value;
                        }
                    })
                    break;
                case "Unassigned":
                    this.setStudentsList();
                    this.studentsList = this.studentsList.filter(value => {
                        studentAssigned = this.studentsAssigned.filter(item => {
                            if(item.id == value.id) return item;
                        })
                        if(studentAssigned.length == 0){
                            return value;
                        }
                    })
                    break;
           }
        },


    }
}
</script>

<style scoped>
    ::v-deep .v-input{
        font-size: 12px;
    }

    ::v-deep .v-text-field input{
        text-align: center;
    }

    ::v-deep .v-select__selections{
        flex-wrap: nowrap;
    }

    ::v-deep .v-data-footer {
        justify-content: flex-start;
    }

    ::v-deep .v-card__actions {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections {
        min-height: 20px;

    }

    ::v-deep .v-data-table-header tr th:nth-child(1){
        width: 80px !important;
    }
</style>

